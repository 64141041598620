// @codekit-prepend "js.cookie.js"
//import Cookies from 'js-cookie';
// LAZYSIZES CFG

$(document).ready(function () {
    window.scrollTo(0, 0);
});

window.lazySizes.cfg.hFac = "1.5";
window.lazySizes.cfg.expand = "1000";
window.lazySizes.cfg.loadMode = 3;
window.lazySizes.cfg.loadHidden = true;

var html = $("html"),
    H = html.outerHeight(true),
    S = $(window).scrollTop(),
    P = S / H;

$(window).scroll(function () {
    S = $(window).scrollTop();
    P = S / H;
});

// Moved to function updateResize
// $(window).resize(_.throttle(function () {
// 	H = html.outerHeight(true);
// 	$(window).scrollTop(P * H);
// }, 50));

/*--------------------------
 *
 *  ONCE FUNCTION
 *
 *--------------------------*/
function once(fn, context) {
    var result;

    return function () {
        if (fn) {
            result = fn.apply(context || this, arguments);
            fn = null;
        }

        return result;
    };
}

/*--------------------------
 *
 *  VARS
 *
 *--------------------------*/

//var TOUCH = 'ontouchstart' in window;
var TOUCH = window.innerWidth <= 1112 ? true : false;

var $inner = $(".projects-inner");
var $grid = $(".projects-grid");
var wh = window.innerHeight;
var ww = window.innerWidth;
var inner_sw = $inner[0].scrollWidth;
var topMax = $inner[0].scrollWidth - ww;
var scrollwidth;

function updateResize() {
    TOUCH = window.innerWidth <= 1112 ? true : false;
    H = html.outerHeight(true);
    if (!TOUCH) {
        $(window).scrollTop(P * H);
    }
    wh = window.innerHeight;
    ww = window.innerWidth;
    scrollwidth = $inner[0].scrollWidth;
    if (!TOUCH) {
        var gridWidth = scrollwidth;
        if (gridWidth) {
            gridWidth = +gridWidth - window.innerHeight / 1;
            $grid.css("height", gridWidth);
        }
        var $item = $(".projects-item.item").first().find(".container");
        var itemWidth = parseInt(($item.innerHeight() / 2) * 3);
        var itemWidthPlusPadding =
            itemWidth +
            parseInt($(".projects-item.item").first().css("padding-left")) * 2;
        $(".projects-item.item").css("width", itemWidthPlusPadding + "px");
    }
}

/*--------------------------
 *
 *  DOC READY + SCROLL
 *
 *--------------------------*/

$(document).ready(function () {
    $(".contact-footnote p").html($(".header-footnote p").html());
    updateResize();
});

var onscroll = (e) => {
    var top = $grid[0].getBoundingClientRect().top;
    var __top = top - window.innerHeight;
    var contactTop = $("#contact")[0].getBoundingClientRect().top;
    $inner[0].scrollLeft = -__top;
};

var onscrollOpacity = (e) => {
    var top = $grid[0].getBoundingClientRect().top;
    var __top = top - window.innerHeight;
    var contactTop = $("#contact")[0].getBoundingClientRect().top;
    var p;
    if (!TOUCH) {
        if (top <= window.innerHeight && top > 0) {
            p = Math.max(Math.min(1 - top / wh, 1), 0);
        } else if (contactTop <= window.innerHeight) {
            p =
                $("#contact")[0].getBoundingClientRect().top /
                window.innerHeight;
        } else if (top < 0) {
            p = 1;
        }
        if (p == 0) {
            p = 0.001;
        }
        if (p > 0.925) {
            p = 1;
        }
        $inner[0].style.opacity = p;
    }
};

if (!TOUCH) {
    onscroll();
    $(window).one("scroll", _.throttle(onscroll, 20));
    $(window).on("scroll", onscrollOpacity);
}

$(window).on("load resize", _.throttle(updateResize, 20));

var preventHorizontal = function (e) {
    if (e.deltaX != 0) {
        e.preventDefault();
    }
};
$("#p-inner").get(0).addEventListener("wheel", preventHorizontal, false);
$(window).on("load", function () {
    $inner[0].style.opacity = 1;
});

//onscroll();

/*--------------------------
 *
 *  GLIDE SLIDER
 *
 *--------------------------*/
var gliderClick = false,
    prevgliderClick = false;
$(document).ready(function () {
    $("body").on("click", ".projects-item.item:not(.is-init)", function (e) {
        if ($(e.target).hasClass("slider-next")) {
            gliderClick = "1";
        } else if ($(e.target).hasClass("slider-prev")) {
            gliderClick = "-1";
        }
        var sliderID = $(this).find(".slider").attr("id");
        var $sliderEle = $(this).find(".slider:not(.glider)");
        var $sliderObj = $(this).find(".slider");
        if ($sliderEle.length) {
            var $arrowNext = $sliderEle.siblings(".slider-next");
            var $arrowPrev = $sliderEle.siblings(".slider-prev");
            var $glideSlider;

            var hammerNext = new Hammer($arrowNext.get(0));
            var hammerPrev = new Hammer($arrowPrev.get(0));
            hammerNext.get("swipe").set({
                direction: Hammer.DIRECTION_HORIZONTAL,
            });
            hammerPrev.get("swipe").set({
                direction: Hammer.DIRECTION_HORIZONTAL,
            });

            hammerNext.on("swipeleft swiperight", function (ev) {
                if (ev.type == "swipeleft") {
                    $arrowNext.trigger("click");
                } else if (ev.type == "swiperight") {
                    $arrowPrev.trigger("click");
                }
            });
            hammerPrev.on("swipeleft swiperight", function (ev) {
                if (ev.type == "swipeleft") {
                    $arrowNext.trigger("click");
                } else if (ev.type == "swiperight") {
                    $arrowPrev.trigger("click");
                }
            });

            $sliderEle
                .get(0)
                .addEventListener("glider-loaded", function (event) {
                    var glider = Glider(this);
                    if (!TOUCH) {
                        if (gliderClick != false) {
                            if (gliderClick == "-1") {
                                gliderClick = $(this).find(".slide").length;
                            }
                            if (prevgliderClick == true) {
                                gliderClick = 2;
                                prevgliderClick = false;
                            }
                            if (!TOUCH) {
                                glider.scrollItem(gliderClick);
                            } else {
                                console.log("hi");
                                glider.scrollTo(20);
                            }
                            gliderClick = false;
                        }
                    }
                });

            // Update counter
            $sliderEle
                .get(0)
                .addEventListener("glider-slide-visible", function (event) {
                    var gliderEl = Glider(this);
                    var glidercurrent = event.detail.slide
                        ? event.detail.slide
                        : "0";
                    if (glidercurrent && $(event.target).hasClass("glider")) {
                        var gCurrent = +glidercurrent + 1;
                        var gTotal = $(event.target).find(".slide").length;
                        var gParent = $(event.target)
                            .siblings(".slider-count")
                            .find("span");
                        gParent.html(gCurrent + "/" + gTotal);
                        $("#cursor").attr(
                            "data-count",
                            gCurrent + "/" + gTotal
                        );
                    }
                });

            $glideSlider = new Glider($sliderEle.get(0), {
                duration: 0.65,
                scrollLock: true,
                //scrollLockDelay: 150,
                rewind: true,
                draggable: true,
                arrows: {
                    prev: $arrowPrev.get(0),
                    next: $arrowNext.get(0),
                },
            });

            $(this).addClass("is-init");
        }
    });

    // Init all sliders on mobile
    if (TOUCH) {
        $(".projects-item.item:not(.is-init)").each(function () {
            //$(this).find('.slider-next').trigger('click');
            var $sliderEle = $(this).find(".slider:not(.glider)");
            if ($sliderEle.length) {
                var $arrowNext = $sliderEle.siblings(".slider-next");
                var $arrowPrev = $sliderEle.siblings(".slider-prev");
                var $glideSlider;

                var hammerNext = new Hammer($arrowNext.get(0));
                var hammerPrev = new Hammer($arrowPrev.get(0));
                hammerNext.get("swipe").set({
                    direction: Hammer.DIRECTION_HORIZONTAL,
                });
                hammerPrev.get("swipe").set({
                    direction: Hammer.DIRECTION_HORIZONTAL,
                });

                hammerNext.on("swipeleft swiperight", function (ev) {
                    if (ev.type == "swipeleft") {
                        $arrowNext.trigger("click");
                    } else if (ev.type == "swiperight") {
                        $arrowPrev.trigger("click");
                    }
                });
                hammerPrev.on("swipeleft swiperight", function (ev) {
                    if (ev.type == "swipeleft") {
                        $arrowNext.trigger("click");
                    } else if (ev.type == "swiperight") {
                        $arrowPrev.trigger("click");
                    }
                });

                $glideSlider = new Glider($sliderEle.get(0), {
                    duration: 0.65,
                    scrollLock: true,
                    //scrollLockDelay: 150,
                    rewind: true,
                    draggable: true,
                    arrows: {
                        prev: $arrowPrev.get(0),
                        next: $arrowNext.get(0),
                    },
                });

                // Update counter
                $sliderEle
                    .get(0)
                    .addEventListener("glider-slide-visible", function (event) {
                        var gliderEl = Glider(this);
                        var glidercurrent = event.detail.slide
                            ? event.detail.slide
                            : "0";
                        if (
                            glidercurrent &&
                            $(event.target).hasClass("glider")
                        ) {
                            var gCurrent = +glidercurrent + 1;
                            var gTotal = $(event.target).find(".slide").length;
                            var gParent = $(event.target)
                                .siblings(".slider-count")
                                .find("span");
                            gParent.html(gCurrent + "/" + gTotal);
                        }
                    });

                $(this).addClass("is-init");
            }
        });
    }

    var $cursor = $("#cursor");

    const bd = document.body,
        cur = $cursor.get(0);
    bd.addEventListener("mousemove", function (n) {
        cur.style.transform = `translate3d(${n.clientX - 10}px, ${
            n.clientY - 10
        }px, 0)`;
    });

    $("body").on("mouseenter mouseleave", ".slider-next", function (e) {
        if (e.type == "mouseenter") {
            let count = $(this).siblings(".slider-count").find("span").html();
            $cursor
                .removeClass("is-prev")
                .addClass("is-next")
                .attr("data-count", count);
        } else {
            $cursor.removeClass("is-prev is-next").removeAttr("data-count");
        }
    });
    $("body").on("mouseenter mouseleave", ".slider-prev", function (e) {
        if (e.type == "mouseenter") {
            let count = $(this).siblings(".slider-count").find("span").html();
            $cursor
                .removeClass("is-next")
                .addClass("is-prev")
                .attr("data-count", count);
        } else {
            $cursor.removeClass("is-prev is-next").removeAttr("data-count");
        }
    });

    $("body").on("mouseenter mouseleave", ".header-scroll-down", function (e) {
        if (e.type == "mouseenter") {
            let count = $(this).find(".slider-count span");
            $cursor.removeClass("is-next is-prev").addClass("is-down");
        } else {
            $cursor
                .removeClass("is-prev is-next is-down")
                .removeAttr("data-count");
        }
    });

    // $('body').on('mouseleave', '.overlay.is-active', function () {
    // 	$(this).removeClass('is-active');
    // });
});

$(".projects-item .container .overlay-trigger").on("click", function () {
    var $dis = $(this);
    $(this).toggleClass("is-active");
    $(this).siblings(".overlay").toggleClass("is-active");
    $(this)
        .parents(".projects-item.item")
        .siblings(".projects-item")
        .find(".is-active")
        .not(".overlay-mobile")
        .not(".overlay-nav-item")
        .removeClass("is-active");
    // $(this).parents('.projects-item.item').one('mouseleave', function () {
    // 	$dis.removeClass('is-active');
    // 	$(this).find('.overlay.is-active').removeClass('is-active');
    // });
});

$("body").on(
    "click",
    ".overlay-nav li.overlay-nav-item:not(.is-active)",
    function () {
        var $dis = $(this);
        var targ = $dis.data("target");
        var $par = $dis.parents(".overlay");
        $par.find(".overlay-mobile, .overlay-nav-item").removeClass(
            "is-active"
        );
        $par.find(targ).addClass("is-active");
        $dis.addClass("is-active");
        //$targ.addClass('is-active');
    }
);

/*--------------------------
 *
 *  HAMMER - SWIPE
 *
 *--------------------------*/

var hammerPrevent = false;

$(document).ready(function () {
    $(".overlay-touch").each(function () {
        var dis = $(this).get(0);
        var $dis = $(this);
        var hammertime = new Hammer(dis);
        hammertime.get("swipe").set({
            direction: Hammer.DIRECTION_HORIZONTAL,
        });

        hammertime.on("swipeleft swiperight", function (ev) {
            $dis.siblings(".overlay-nav")
                .find("li:not(.is-active)")
                .trigger("click");
        });
    });
});

/*--------------------------
 *
 *  SCROLLTO
 *
 *--------------------------*/

$("body").on("click", ".scroll-to", function () {
    var dis = $(this);
    if (dis.data("scroll-to")) {
        var delay = dis.data("scroll-delay") ? dis.data("scroll-delay") : "0";
        if (dis.data("scroll-to") == "projects") {
            setTimeout(function () {
                $("body, html").animate(
                    {
                        scrollTop: !TOUCH
                            ? $(window).innerHeight()
                            : $(".projects-grid").offset().top,
                    },
                    2000,
                    "easeInOutExpo"
                );
            }, delay);
        } else if (dis.data("scroll-to") == "#contact") {
            setTimeout(function () {
                // window.scrollTo({
                // 	top: $('#contact').offset().top,
                // 	behavior: 'smooth'
                // });
                $("body, html").animate(
                    {
                        scrollTop: $("#contact").offset().top,
                    },
                    2000,
                    "easeInOutExpo"
                );
            }, delay);
        } else {
            baScrollTo(dis.data("scroll-to"));
        }
    }
});
$("body").on("click", ".scroll-top", function () {
    // window.scrollTo({
    // 	top: 0,
    // 	behavior: 'smooth'
    // });
    $("body, html").animate(
        {
            scrollTop: 0,
        },
        2000,
        "easeInOutExpo"
    );
    if ($("html").hasClass("menu-is-open")) {
        menuClose();
    }
});

function baScrollTo(target) {
    let $target = $(target);
    if ($target.length) {
        // $target.get(0).scrollIntoView({
        // 	behavior: "smooth"
        // });
        $("body, html").animate(
            {
                scrollTop: $target.offset().top,
            },
            2000,
            "easeInOutExpo"
        );
    }
}

$("body").on("click", ".contact-logo", function () {
    if (TOUCH) {
        //iNoBounce.disable();
    }
    $(".contact-overlay").fadeOut();
    $("body, html").removeClass("contact-is-open");
    $("body, html").animate(
        {
            scrollTop: 0,
        },
        2000,
        "easeInOutExpo"
    );
});

/*--------------------------
 *
 *  CONTACT
 *
 *--------------------------*/
$("body").on("click", ".contact-open, .contact-close", function () {
    $(".contact-overlay").fadeToggle(function () {
        if ($("body").hasClass("contact-is-open") && TOUCH) {
            $(".contact-overlay").css("display", "flex");
        }
    });
    $("body, html").toggleClass("contact-is-open");
    if ($("body").hasClass("contact-is-open") && TOUCH) {
        //iNoBounce.enable();
    } else {
        if (TOUCH) {
            //iNoBounce.disable();
        }
    }
});
$("body").on("click", ".contact-close", function () {
    $("body, html").removeClass("contact-is-open");
    //iNoBounce.disable();
});

var barTop = $(".bar").offset().top;
var barBottom = barTop + $(".bar").height();
var contactTop = TOUCH
    ? $(".contact").offset().top - $(".bar").innerHeight()
    : $(".contact").offset().top -
      ($(window).innerHeight() - parseInt($(".bar").css("padding-bottom")));
var projectsTop = TOUCH
    ? $(".projects-grid").offset().top - $(".bar").innerHeight()
    : $(window).innerHeight();

var updateScrolls = function () {
    contactTop = TOUCH
        ? $(".contact").offset().top - $(".bar").innerHeight()
        : $(".contact").offset().top -
          ($(window).innerHeight() - parseInt($(".bar").css("padding-bottom")));
    barTop = $(".bar").offset().top;
    barBottom = barTop + $(".bar").height();
    projectsTop = TOUCH
        ? $(".projects-grid").offset().top - $(".bar").innerHeight()
        : $(window).innerHeight();
    var currentScroll = $(this).scrollTop();

    if (TOUCH) {
        if (currentScroll >= projectsTop && currentScroll < contactTop) {
            $(".bar").removeClass("is-dark").addClass("is-light");
        }
        if (currentScroll < projectsTop) {
            $(".bar").removeClass("is-dark is-light");
        }
    }

    if (currentScroll >= contactTop) {
        $(".bar").removeClass("is-light").addClass("is-dark");
    } else {
        $(".bar").removeClass("is-dark");
    }
};

$(window).on("load resize scroll", updateScrolls);

/*--------------------------
 *
 *  DISABLE RIGHT CLICK ON IMAGES
 *
 *--------------------------*/
$("img").on("contextmenu", function (e) {
    if (!$("body").hasClass("is-logged-in")) {
        e.preventDefault();
    }
});

/*--------------------------
 *
 *  MENU MOBILE
 *
 *--------------------------*/

function menuToggle() {
    $("html").toggleClass("menu-is-open");
    if ($("html").hasClass("menu-is-open")) {
        $(".bar-overlay").fadeIn();
        if (TOUCH) {
            //iNoBounce.enable();
        }
    } else {
        $(".bar-overlay").fadeOut();
        if (TOUCH) {
            //iNoBounce.disable();
        }
    }
}

function menuClose() {
    $("html").removeClass("menu-is-open");
    $(".bar-overlay").fadeOut();
    if (TOUCH) {
        //iNoBounce.disable();
    }
}

function menuOpen() {
    $("html").addClass("menu-is-open");
    $(".bar-overlay").fadeIn();
    if (TOUCH) {
        //iNoBounce.enable();
    }
}
$("body").on("click", ".bar-toggle", menuToggle);

$("body").on("click", ".menu-item-mobile", menuClose);

$("body").on("click", ".bar-logo, .menu-item-mobile", function () {
    if (TOUCH) {
        var $dis = $(this);
        $dis.addClass("is-playing");
        setTimeout(function () {
            $dis.removeClass("is-playing");
        }, 500);
    }
});

// Contact scroll top
$("body").on("click", ".contact-arrow-top", function () {
    $(".contact-overlay").animate(
        {
            scrollTop: 0,
        },
        1200,
        "easeInOutExpo"
    );
});

/*--------------------------
 *
 *  TIMES
 *
 *--------------------------*/
// function clocksUpdate() {
// 	const DateTime = luxon.DateTime;
// 	$('.times-item').each(function () {
// 		var $dis = $(this);
// 		var now;
// 		if ($dis.data('timezone')) {
// 			$timeEl = $dis.find('.times-time');
// 			now = DateTime.local().setZone($dis.data('timezone'));
// 			$timeEl.html(now.toFormat('HH:mm:ss'));
// 		};
// 	});
// }

//setInterval(clocksUpdate, 1000);

// Mobile hover fix
document.addEventListener("touchstart", function () {}, true);

/* -------------------------------------------------------------------------- */
/*                              Language on load                              */
/* -------------------------------------------------------------------------- */

// https://html-online.com/articles/get-url-parameters-javascript/
function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
            vars[key] = value;
        }
    );
    return vars;
}
function getUrlParam(parameter, defaultvalue) {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
        urlparameter = getUrlVars()[parameter];
    }
    return urlparameter;
}

const $langContainer = $(".lang-fixed-container");
const userLocale = navigator.language;

const translatedUrl = {
    ja: "https://brody-associates.com/jp/",
    en: "https://brody-associates.com/",
    ko: "https://brody-associates.com/ko/",
    zh: "https://brody-associates.com/zh/",
};

function initLangs() {
    console.log("User locale:", userLocale);
    console.log("Current path", window.location.pathname);

    const userCookie = Cookies.get("brody_lang");

    console.log("Cookie", userCookie);
    if (!userCookie) {
        if (userLocale) {
            // If it's english version
            if (window.location.pathname == "/") {
                // Japanese
                if (userLocale == "ja") {
                    // If browser is JP, redirect and set cookie
                    console.log("Japanese browser detected, redirecting");
                    window.location.href = translatedUrl.ja + "?auto=1";
                }
                // Korean
                if (userLocale.indexOf("ko") > -1) {
                    console.log("Korean browser detected, redirecting");
                    window.location.href = translatedUrl.ko + "?auto=1";
                }
                // Simplified chinese
                if (userLocale.indexOf("zh") > -1) {
                    console.log("Chinese browser detected, redirecting");
                    window.location.href = translatedUrl.zh + "?auto=1";
                }
            }
        }
    } else {
        if (userLocale) {
            // If it's english version
            if (window.location.pathname == "/" && userCookie != "en") {
                // If preference is JP, navigate
                if (userCookie == "ja") {
                    window.location.href = translatedUrl.ja;
                }
                if (userCookie == "ko") {
                    window.location.href = translatedUrl.ko;
                }
                if (userCookie == "zh") {
                    window.location.href = translatedUrl.zh;
                }
            }
        }
    }

    // Check if popup needs to be shown
    let paramAuto = getUrlParam("auto", "Empty");
    let paramKeep = getUrlParam("keep", "Empty");

    //console.log('keep', paramKeep, 'auto', paramAuto);
    // Auto redirect + set cookie
    if (paramKeep == 1) {
        // Keep english
        console.log("keep english");
        Cookies.set("brody_lang", "en", { expires: 1 });
    } else if (paramAuto == 1) {
        setTimeout(function () {
            $langContainer.css("display", "flex").hide().fadeIn();
        }, 1500);
    }

    const $popupAccept = $langContainer.find(".button.accept");
    const $popupCancel = $langContainer.find(".button.cancel");

    $popupAccept.on("click", function () {
        let _userLocale = userLocale;

        // Korean variations
        if (_userLocale.indexOf("ko") > -1) {
            _userLocale = "ko";
        }
        // Chinese variations
        if (_userLocale.indexOf("zh") > -1) {
            _userLocale = "zh";
        }

        Cookies.set("brody_lang", _userLocale, { expires: 1 });
        $langContainer.fadeOut();
    });

    $popupCancel.on("click", function () {
        Cookies.set("brody_lang", "en", { expires: 1 });
        $langContainer.fadeOut();
        setTimeout(function () {
            window.location.href = translatedUrl.en + "?keep=1";
        }, 1000);
    });
}

initLangs();

/*--------------------------
 *
 *  LOADER
 *
 *--------------------------*/

var ignore_unload = false;

// Prevent if link has mailto or tel
$("a[href^=mailto], a[href^=tel]").on("click", function () {
    ignore_unload = true;
});

$(window).on("beforeunload", function (e) {
    if (!ignore_unload) {
        $("#loader").fadeIn(function () {
            window.scrollTo(0, 0);
        });
    }
    ignore_unload = false;
});
$(window).on("load", function () {
    // iNoBounce to avoid overscrolling on fixed containers
    if (TOUCH) {
        //iNoBounce.disable();
    }
    $("#loader").fadeOut(function () {
        $(".header-text, .header-footnote, body").addClass("is-loaded");
        setTimeout(function () {
            $("body").removeClass("is-loaded");
        }, 750);
    });
});
